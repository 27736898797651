import React from 'react';
import ReactPlayer from 'react-player';
import AdComponent from '../components/AdComponent'; // Adjust the path as per your project structure

const MusicPlayer = ({ currentEpisode }) => {
  return (
    <div className="music-player">
      <h2>{currentEpisode?.title || 'Select an Episode to Play'}</h2>
     <div className='music-player-container'>
     <button className='button' onClick={() => window.location.href = '/'}>Home</button>
     <ReactPlayer
        url={currentEpisode?.audioUrl}
        playing
        controls
        width="100%"
        height="100px"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // Disable download
            },
          },
        }}
      />
     </div>
    </div>
  );
};

export default MusicPlayer;
