import React from 'react';

const EpisodeList = ({ episodes, onEpisodeClick }) => {
  return (
    <div className="episode-list">
      {episodes.map((episode, index) => (
        <div
          key={episode.id}
          className="episode-item"
          onClick={() =>
            onEpisodeClick({
              id: episode.id,
              title: episode.title,
              audioUrl: episode.url, // Use content.url first
              thumbnail: episode.thumbnail_image,
            })
          }
        >
          <img src={episode.image} alt={episode.title} className="episode-thumbnail" />
          <div className="episode-details">
            <h3>{episode.title}</h3>
            <p>Duration: {Math.floor(episode.duration_s / 60)} min {episode.duration_s % 60} sec</p>
            <p>{episode.n_plays.toLocaleString()} plays</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EpisodeList;
