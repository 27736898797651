import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import Home from './pages/Home';
import EpisodesPage from './pages/EpisodesPage';
import './styles.css';
import React, { useRef, useEffect, useState } from 'react';

const App = () => {
    const pageTransition = {
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: '-100%' },
    };
    // useEffect(() => {
    //     const disableShortcuts = (e) => {
    //       if (e.ctrlKey && (e.key === 'u' || e.key === 's' || e.key === 'Shift')) {
    //         e.preventDefault();
    //       }
    //     };
    //     window.addEventListener('keydown', disableShortcuts);
    //     return () => window.removeEventListener('keydown', disableShortcuts);
    //   }, []);
      
    // useEffect(() => {
    //     const disableContextMenu = (e) => e.preventDefault();
    //     window.addEventListener('contextmenu', disableContextMenu);
    //     return () => window.removeEventListener('contextmenu', disableContextMenu);
    //   }, []);
  return (
    <Router>
      <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/episodes" element={<EpisodesPage />} />
        </Routes>
      </motion.div>
    </Router>
  );
};

export default App;
