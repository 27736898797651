import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import EpisodeList from '../components/EpisodeList';
import MusicPlayer from '../components/MusicPlayer';

const EpisodesPage = () => {
  const { state } = useLocation();
  const [episodes, setEpisodes] = useState(state.episodes || []);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredEpisodes = episodes.filter((episode) =>
    episode.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="episodes-page">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search episodes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <EpisodeList episodes={filteredEpisodes} onEpisodeClick={setCurrentEpisode} />
      <MusicPlayer currentEpisode={currentEpisode} />
    </div>
  );
};

export default EpisodesPage;
