import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchEpisodes } from '../api';
import ClipLoader from 'react-spinners/ClipLoader';
import AdComponent from '../components/AdComponent'; // Adjust the path as per your project structure

const Home = () => {
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const extractSlugFromURL = (url) => {
    try {
      const parsedURL = new URL(url);
      const pathSegments = parsedURL.pathname.split('/');
      return pathSegments[pathSegments.length - 1]; // Get the last segment
    } catch (error) {
      // If the input is not a valid URL, return it as-is (assuming it's already a slug)
      return url.trim();
    }
  };

  const handleSearch = async () => {
    if (!userInput.trim()) return alert('Please enter a search term');

    const slug = extractSlugFromURL(userInput);
    if (!slug) return alert('Invalid URL or search term');

    setLoading(true);
    try {
      const response = await fetchEpisodes(slug); // Pass the extracted slug
      setLoading(false);
      navigate('/episodes', { state: { episodes: response.data.alldata } });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching episodes:', error.message);
    }
  };

  return (
    <div className='z-in'>
      <div className="home-container">
        <h1>Discover Music</h1>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter your search term or URL"
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button" disabled={loading}>
          {loading ? 'Loading...' : 'Search'}
        </button>
        {loading && <ClipLoader color="#ffffff" loading={loading} size={50} />}
      </div>
      <AdComponent />
    </div>
  );
};

export default Home;
